@import ../../styles/helpers

.label
    margin-top: 12px
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.input
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid $neutrals6
    background: none
    +poppins
    +caption-bold-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    +placeholder
        color: $neutrals4
    &:focus
        border-color: $neutrals4