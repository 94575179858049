@import ../../styles/helpers

.section
    +x
        padding-top: 80px
    +m
        padding-top: 64px

.container
    display: flex
    align-items: flex-start
    +t
        display: block

.wrapper
    flex: 0 0 calc(100% - 352px)
    width: calc(100% - 352px)
    padding-right: 128px
    +x
        padding-right: 64px
    +d
        flex: 0 0 calc(100% - 304px)
        width: calc(100% - 304px)
        padding-right: 32px
    +t
        width: 100%
        padding: 0

.preview
    flex-shrink: 0
    width: 352px  
    +d
        width: 304px 
    +t
        display: flex
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        padding: 48px
        background: rgba($neutrals1,.9)
        overflow: auto
        z-index: 999
        visibility: hidden
        opacity: 0
        transition: all .4s
        +dark
            background: rgba($neutrals2,.9)
        &.active
            visibility: visible
            opacity: 1
    +m
        padding: 32px 16px

.head
    display: flex
    margin-bottom: 40px
    +d
        flex-direction: column-reverse
        align-items: flex-start
    +m
        margin-bottom: 32px
    .button
        flex-shrink: 0
        margin-left: 24px
        +d
            margin: 0 0 16px

.title
    margin-right: auto

.list
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom: 1px solid $neutrals6
    +m
        margin-bottom: 32px
        padding-bottom: 32px
    +dark
        border-color: $neutrals3

.item
    &:not(:last-child)
        margin-bottom: 40px
        border-radius: 16px
        +m
            margin-bottom: 32px

.note
    margin-top: 4px
    +caption-2
    color: $neutrals4

.file
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 194px
    border-radius: 16px
    overflow: hidden
    background: $neutrals7
    +dark
        background: $neutrals2

.image
    position: absolute
    z-index: 1

.load
    position: absolute
    top: 0
    left: 0
    font-size: 400px
    z-index: 999
    opacity: 0,
    height: 100%
    
.icon
    margin-bottom: 10px
    svg
        fill: $neutrals4
    
.format
    +caption-2
    color: $neutrals4

.category
    +body-bold-2

.fieldset
    margin-top: 32px
    .field
        &:not(:last-child)
            margin-bottom: 32px
            +m
                margin-bottom: 20px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5
    
.row
    display: flex
    margin: 0 -10px
    +m
        display: block
        margin: 0

.col
    flex: 0 0 calc(33.333% - 20px)
    width: calc(33.333% - 20px)
    margin: 0 10px
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 20px

.options
    margin-bottom: 40px
    +m
        margin-bottom: 32px

.option
    display: flex
    align-items: flex-start
    &:not(:last-child)
        margin-bottom: 32px

.box
    flex-grow: 1

.switch
    flex-shrink: 0
    margin-left: 24px

.text
    margin-top: 4px
    +caption-2
    color: $neutrals4
    
.foot
    display: flex
    align-items: center
    +m
        display: block
        text-align: center
    .button
        +m
            width: 100%
        &:not(:last-child)
            margin-right: 24px
            +m
                margin: 0 0 12px

.saving
    display: inline-flex
    align-items: center
    margin-left: auto
    +button-1

.loader
    margin-left: 24px
  