@import ../../../styles/helpers

.title
    margin-bottom: 32px

.info
    margin-bottom: 32px
    +body-2
    strong
        font-weight: 500

.stage
    margin-bottom: 16px
    +body-bold-1
.stageBid
    margin-bottom: 16px
    +body-2
    
.row
    display: flex
    padding-top: 12px
    &:first-child
        padding: 0 0 12px
        border-bottom: 1px solid $neutrals6
        font-weight: 500
        +dark
            border-color: $neutrals3

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 16px
        font-weight: 500
    
.btns
    margin-top: 32px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px

.input
    width: 100%
    height: 48px
    border-bottom: 1px solid $neutrals6
    background: none
    border-radius: 0
    +poppins
    +body-bold-2
    color: $neutrals2
    transform: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    +placeholder
        color: $neutrals4
    &:focus
        border-color: $neutrals4
