@import ../../../styles/helpers

.title
    margin-bottom: 32px

.text
    margin-bottom: 32px
    +body-2
    color: $neutrals4

.info
    margin-bottom: 3px
    +body-bold-1

.input
    width: 100%
    height: 48px
    border-bottom: 1px solid $neutrals6
    +poppins
    +body-bold-2
    color: $neutrals2
    +placeholder
        color: $neutrals4

.btns
    margin-top: 32px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px